<template>
  <div>
    <ul class="status_ul flex">
      <li :class="examine_status === item.id ? 'active' : ''" v-for="(item, index) in status_list"
          :key="index" @click="statusShow(item)">
        {{ item.title }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    status_list: Array,
  },

  data() {
    return {
      examine_status: '',
    }
  },
  methods: {
    statusShow(item) {
      this.examine_status = item.id
      this.$emit('status_tab', item.id)
    }
  },
}
</script>
<style lang="scss" scoped>
::v-deep .item {
  position: absolute;
  right: -10px;
  top: -11px;
  z-index: 10;

}

::v-deep .el-badge__content {
  background: #ff0000;
}

.status_ul {
  justify-content: space-between;

  li {
    position: relative;
    padding: 0.5rem 0.7rem;
    box-sizing: border-box;
    text-align: center;
    border-radius: 0.42rem;
    background: #ffffff;
    box-shadow: 2px 2px 4px rgba(122, 122, 122, 0.25);
    cursor: pointer;
    color: #108cdd;
    margin-left: .5rem;
  }

  .active {
    background: #108cdd;
    border-radius: 0.42rem;
    color: #fff;
  }
}

.status_num {
  position: absolute;
  right: -0.4rem;
  top: -0.5rem;
  width: 1.3rem;
  text-align: center;
  line-height: 1.3rem;
  background: #ff0000;
  border-radius: 50%;
  color: #fff;
}
</style>